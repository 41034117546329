import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { useSEO } from "../hook";
import { useContext } from "react";
import { formatNumber } from "src/utils/Functions";

export const H1 = ({ total = '' }) => {
	const { seoMetaTags } = useSEO();
	const { country_code } = useContext(ConfigStateContext);

	if (country_code === "CO" && seoMetaTags?.h1?.includes("En Pozo")) {
		seoMetaTags.h1 = seoMetaTags.h1.replace("En Pozo", "Sobre Planos");
	}
	if (country_code === "CO" && seoMetaTags?.h1?.includes("A estrenar")) {
		seoMetaTags.h1 = seoMetaTags.h1.replace("A estrenar", "Nuevos");
	}
	if (country_code === "CO" && seoMetaTags?.h1?.includes("Venta de seo:")) {
		seoMetaTags.h1 = seoMetaTags.h1.replace("Venta de seo:", "Venta: ");
	}
	return <h1 className="subhead subhead-bold high">{seoMetaTags?.h1}</h1>
}
